import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Beteiligungen from './Beteiligungen/Beteiligungen'
import Person from './Person/Person'

const Member = () => {
  return (
    <Layout authLevelRequired={[Level.MEMBER]} noFooter>
      <Routes>
        <Route path={'beteiligungen/*'} element={<Beteiligungen />} />
        <Route path={'informationen/*'} element={<Person />} />
        <Route path={'*'} element={<Navigate replace to="/member/beteiligungen" />} />
      </Routes>
    </Layout>
  )
}

export default Member
