import { DocumentIcon, FolderIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ItemType, Item as ItemEntry, SwitchFolder, RemoveItem } from '../../../../types/Storage/Storage'
import RemoveConfirmation from '../../RemoveConfirmation/RemoveConfirmation'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

type Props = {
  item: ItemEntry
  switchFolder: SwitchFolder
  removeItem?: RemoveItem
  disableTemplateInteractions?: boolean
}

const Item: React.FC<Props> = ({ item, switchFolder, removeItem, disableTemplateInteractions = true }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isTemplateFolder = item.type === ItemType.FOLDER && !item.targetUUID

  const disableInteractions = disableTemplateInteractions && isTemplateFolder

  return (
    <div className="hover:bg-turquoise group transition-all text-white rounded-lg cursor-pointer">
      <div className="flex justify-between items-center gap-3">
        {item.type === ItemType.FOLDER && (
          <div className="flex items-center gap-2 pl-2 py-1 w-full" onClick={() => switchFolder(item.uuid)}>
            <FolderIcon className="stroke-turquoise group-hover:stroke-white w-6" />
            <div className="group-hover:text-white">{item.name}</div>
          </div>
        )}
        {item.type === ItemType.FILE && (
          <a href={item.url} target="_blank" rel="noreferrer" className="flex items-center gap-2 pl-2 py-1 w-full">
            <DocumentIcon className="stroke-turquoise group-hover:stroke-white w-6" />
            <div className="group-hover:text-white">{item.name}</div>
          </a>
        )}
        {!disableInteractions && (
          <div className="pr-2 py-1">
            {!!removeItem && (
              <RemoveConfirmation onConfirm={removeItem(item.uuid)} text={t.UI.removeConfirmation.text(item.name)}>
                <TrashIcon className="h-5 w-5 stroke-gray hover:stroke-red transition-all cursor-pointer" />
              </RemoveConfirmation>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Item
