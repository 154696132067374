import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Person } from '../../../../types/Person'
import { useCallback, useEffect, useState } from 'react'
import Container from '../../../UI/Container/Container'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import Login from './Section/Login'
import Kontakt from './Section/Kontakt'
import Steuer from './Section/Steuer'
import Pfaendung from './Section/Pfaendung'
import Todesfall from './Section/Todesfall'
import Beteiligungen from './Section/Beteiligungen'
import Inaktivitaet from './Section/Inaktivitaet'
import Accordion, { SectionItem } from '../../../UI/Accordion/Accordion'
import styled from 'styled-components'
import useStatusValidator from './useStatusValidator/useStatusValidator'
import { ValidationProp } from './useStatusValidator/types'
import { useParams } from 'react-router-dom'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import useHasChanged from '../../../hooks/useHasChanged'
import Uebertragungen from './Section/Uebertragungen'
import Save from '../../../UI/Save/Save'
import useTodoRemoveAdmin from '../../Todo/hooks/useTodoRemoveAdmin'
import useTodoCreateAdmin from '../../Todo/hooks/useTodoCreateAdmin'
import { Prio, TargetType, Type as TodoType } from '../../../../types/Todo/Todo'

export const InputWrapper = styled.div<{ itemsPerRow?: number }>`
  width: ${({ itemsPerRow: i }) => (i ? `calc(100% / ${i} - 10px * ${i - 1} / ${i})` : 'calc(100% / 2 - 5px)')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export type SectionProps<T> = {
  person?: Person
  data?: T
  set?: (value: T) => void
  disabled?: boolean
}

const Upsert = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Person>()
  const statusValidator = useStatusValidator(data)
  const hasChanged = useHasChanged(data)
  const { uuid } = useParams<{ uuid: string }>()
  const personFeature = crud.use(props.PERSON)
  const toast = useToast()
  const [hasMissingInformation, setHasMissingInformation] = useState(false)
  const removeTodo = useTodoRemoveAdmin()
  const createTodo = useTodoCreateAdmin()

  useEffect(() => {
    const base: Person = {
      login: {},
      kontakt: {},
      steuer: {},
      pfaendung: {},
      todesfall: {},
      inaktivitaet: {},
    }
    const getPerson = async () => {
      const person = await personFeature.get({ uuid })
      if (person) {
        setData(person)
        hasChanged.init(person)
      } else {
        toast(t.person.upsert.errorLoading, Type.ERROR)
      }
    }
    if (!data) {
      if (uuid) {
        getPerson()
      } else {
        setData(base)
        hasChanged.init(base)
      }
    }
  }, [uuid, personFeature, toast, t.person.upsert.errorLoading, hasChanged, data])

  const items = useCallback((): SectionItem[] => {
    const set =
      <T,>(key: keyof Person) =>
      (value: keyof T) =>
        setData({ ...data, [key]: value })

    const items: SectionItem[] = [
      // {
      //   title: t.person.category.uebersicht,
      //   content: <div></div>,
      //   id: 'uebersicht',
      // },
      {
        title: t.person.category.login,
        content: <Login data={data.login} set={set('login')} />,
        status: statusValidator(ValidationProp.LOGIN),
        id: 'login',
      },
      {
        title: t.person.category.kontakt,
        content: <Kontakt data={data.kontakt} set={set('kontakt')} />,
        status: statusValidator(ValidationProp.KONTAKT),
        id: 'kontakt',
      },
      {
        title: t.person.category.steuer,
        content: <Steuer data={data.steuer} set={set('steuer')} />,
        status: statusValidator(ValidationProp.STEUER),
        id: 'steuer',
      },
    ]
    if (!!data.uuid) {
      items.push(
        {
          title: t.person.category.beteiligungen,
          content: <Beteiligungen person={data} data={null} set={null} />,
          id: 'beteiligungen',
        },
        {
          title: t.person.category.pfaendung,
          content: <Pfaendung person={data} data={data.pfaendung} set={set('pfaendung')} />,
          id: 'pfaendung',
        },
        {
          title: t.person.category.todesfall,
          content: <Todesfall person={data} data={data.todesfall} set={set('todesfall')} />,
          id: 'todesfall',
        },
        {
          title: t.person.category.uebertragung,
          content: <Uebertragungen person={data} data={null} set={null} />,
          id: 'uebertragung',
        },
        {
          title: t.person.category.inaktivitaet,
          content: <Inaktivitaet data={data.inaktivitaet} set={set('inaktivitaet')} />,
          status: statusValidator(ValidationProp.INAKTIVITAET),
          id: 'inaktivitaet',
        },
      )
    }
    return items
  }, [data, statusValidator, t])

  useEffect(() => {
    if (data) {
      const hasMissingNeededInformation = items().reduce((acc, item) => {
        if (typeof item.status === 'boolean') {
          if (!item.status) return true
        } else if (!!item.status) {
          if (item.status.missingNEEDED.length > 0) return true
        }
        return acc
      }, false)
      if (hasMissingInformation !== hasMissingNeededInformation) {
        setHasMissingInformation(hasMissingNeededInformation)
      }
    }
  }, [data, hasMissingInformation, items])

  const savedCallback = (person: Person) => {
    setData(person)
    hasChanged.init(person)
    if (hasMissingInformation) {
      createTodo(TodoType.STAMMDATEN, TargetType.PERSON, Prio.HIGH, {
        uuid: person.uuid,
      })
    } else {
      removeTodo(TodoType.STAMMDATEN, TargetType.PERSON, person.uuid)
    }
    toast(t.person.upsert.success, Type.SUCCESS)
  }

  if (!data) return null
  return (
    <Container>
      <div className="flex flex-col gap-1 pt-10 pb-20">
        <div className="flex gap-3 items-center">
          <UserGroupIcon className="stroke-blue w-10" />
          {!!data.uuid && <div className="font-bold text-lg text-blue">{t.person.upsert.updateTitle(data)}</div>}
          {!data.uuid && <div className="font-bold text-lg text-blue">{t.person.upsert.createTitle}</div>}
        </div>
        <Accordion className="mt-3" items={items()} />
      </div>
      <Save
        data={data}
        upsert={personFeature.upsert}
        hasChanged={hasChanged.hasChanged}
        successCallback={savedCallback}
        cancelLink={'/admin/person'}
      />
    </Container>
  )
}

export default Upsert
