import { getUUID } from '../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

type Props = {
  className?: string
  color?: string
  onChange?: (value: any) => void
  value?: any
  label?: string
  items?: { label: string; value: any }[]
  required?: boolean
  emptySelection?: string
  showEmptySelection?: boolean
  disabled?: boolean
}

const Dropdown: React.FC<Props> = ({
  className = '',
  color,
  onChange,
  value,
  label,
  items = [],
  required = false,
  emptySelection,
  showEmptySelection = true,
  disabled = false,
}) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const getDisabledValue = () => {
    const item = items.find((i) => i.value === value)
    if (!item) return ''
    return item.label
  }

  return (
    <div className={`w-full ${className}`}>
      {label && <label className="text-white text-sm py-1 px-2 rounded-full block mb-1 font-bold bg-turquoise w-full">{label}</label>}
      {disabled ? (
        <div className={`placeholder-gray h-[32px] w-full py-1 px-2 text-black rounded-lg focus:outline-none focus:shadow-outline`}>
          {getDisabledValue()}
        </div>
      ) : (
        <select
          required={required}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={`placeholder-gray h-[32px] w-full py-1 px-2 bg-lightGray text-black rounded-lg focus:outline-none focus:shadow-outline`}
        >
          {showEmptySelection && (
            <option key={getUUID()} value={''} color={color}>
              {emptySelection || translation.UI.dropdown.emptySelection}
            </option>
          )}
          {items.map((item) => (
            <option key={getUUID()} value={item.value} color={color}>
              {item.label}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

export default Dropdown
