import { Route, Routes } from 'react-router-dom'
import List from './List'
import Upsert from './Upsert/Upsert'
import Versammlung from './Versammlung/Versammlung'
import Storage from './Storage/Storage'

const Company = () => {
  return (
    <Routes>
      <Route path={'/:uuid'} element={<Upsert />} />
      <Route path={'/create'} element={<Upsert />} />
      <Route path={'/versammlung/*'} element={<Versammlung />} />
      <Route path={'/storage/:companyUUID'} element={<Storage />} />
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}

export default Company
