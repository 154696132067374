import Container from '../../UI/Container/Container'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import StorageTemplate from './StorageTemplate/StorageTemplate'

const Settings = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const Wrapper = ({ title, hint, children }) => (
    <div className="bg-white shadow-md rounded-md p-5">
      <div className="font-bold text-lg">{title}</div>
      <div className="text-sm text-gray-400">{hint}</div>
      <div className="mt-3">{children}</div>
    </div>
  )

  return (
    <Container>
      <div className="py-10">
        <div className="flex gap-3 items-center">
          <Cog8ToothIcon className="stroke-blue w-10" />
          <div className="font-bold text-lg text-blue">{t.settings.title}</div>
        </div>
        <div className="flex flex-col gap-5 mt-5">
          <Wrapper title={t.settings.storageTemplate.title} hint={t.settings.storageTemplate.hint}>
            <StorageTemplate />
          </Wrapper>
        </div>
      </div>
    </Container>
  )
}

export default Settings
