import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Button from '../../UI/Button/Button'
import Modal from '../../UI/Modal/Modal'
import useToast from '../../hooks/useToast'
import Input from '../../UI/Input/Input'
import { CreateFolder as CreateFolderType } from '../../../types/Storage/Storage'

const CreateFolder: React.FC<{ callback: CreateFolderType }> = ({ callback }) => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    const trimmedName = name.trim()
    if (trimmedName.length > 0) {
      callback(name)
      toggle()
    } else {
      toast(t.storage.error.emptyFolderName)
    }
  }

  return (
    <>
      <Button onClick={toggle} text={t.storage.createFolder.cta} />
      <Modal onConfirm={confirm} show={open} onClose={toggle} title={t.storage.createFolder.title}>
        <Input required placeholder={t.storage.createFolder.placeholder} onChange={setName} value={name} />
      </Modal>
    </>
  )
}

export default CreateFolder
