import { Company } from '../Company'
import { Person } from '../Person'
import { StammdatenCompany, StammdatenPerson } from './Payload'

// used for interpretation of the payload
export enum Type {
  STAMMDATEN = 'STAMMDATEN',
}

// used for filtering todos
export enum TargetType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export enum Prio {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type Payload = StammdatenPerson | StammdatenCompany

export type Todo = {
  uuid?: string
  type: Type
  targetType: TargetType
  prio: Prio
  primaryReceiverPersonUUIDList: string[]
  secondaryReceiverPersonUUIDList: string[]
  payload: Payload

  // dynamic properties
  person?: Person
  company?: Company
}
