import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Person } from '../../../../types/Person'
import { useCallback, useEffect, useState } from 'react'
import Container from '../../../UI/Container/Container'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import Accordion, { SectionItem } from '../../../UI/Accordion/Accordion'
import styled from 'styled-components'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import useHasChanged from '../../../hooks/useHasChanged'
import Kontakt from '../../../Admin/Person/Upsert/Section/Kontakt'
import Steuer from '../../../Admin/Person/Upsert/Section/Steuer'
import Beteiligungen from '../../../Admin/Person/Upsert/Section/Beteiligungen'

export const InputWrapper = styled.div<{ itemsPerRow?: number }>`
  width: ${({ itemsPerRow: i }) => (i ? `calc(100% / ${i} - 10px * ${i - 1} / ${i})` : 'calc(100% / 2 - 5px)')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export type SectionProps<T> = {
  person?: Person
  data: T
  set?: (value: T) => void
}

const Upsert = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Person>()
  const hasChanged = useHasChanged(data)
  const personFeature = crud.use(props.PERSON)
  const toast = useToast()
  const [hasMissingInformation, setHasMissingInformation] = useState(false)

  useEffect(() => {
    const getPerson = async () => {
      const person = await personFeature.get({})
      if (person) {
        setData(person)
        hasChanged.init(person)
      } else {
        toast(t.person.upsert.errorLoading, Type.ERROR)
      }
    }
    if (!data) {
      getPerson()
    }
  }, [personFeature, toast, t.person.upsert.errorLoading, hasChanged, data])

  const items = useCallback((): SectionItem[] => {
    const items = [
      // {
      //   title: t.person.category.login,
      //   content: <LoginInformation data={data.login} />,
      //   status: statusValidator(ValidationProp.LOGIN),
      //   id: 'login',
      // },
      {
        title: t.person.category.kontakt,
        content: <Kontakt data={data.kontakt} />,
        // status: statusValidator(ValidationProp.KONTAKT),
        id: 'kontakt',
      },
      {
        title: t.person.category.steuer,
        content: <Steuer data={data.steuer} />,
        // status: statusValidator(ValidationProp.STEUER),
        id: 'steuer',
      },
    ]
    if (!!data.uuid) {
      items.push(
        {
          title: t.person.category.beteiligungen,
          content: <Beteiligungen person={data} disabled />,
          id: 'beteiligungen',
        },
        // {
        //   title: t.person.category.pfaendung,
        //   content: <Pfaendung person={data} data={data.pfaendung} />,
        //   id: 'pfaendung',
        // },
        // {
        //   title: t.person.category.todesfall,
        //   content: <Todesfall person={data} data={data.todesfall} />,
        //   id: 'todesfall',
        // },
        // {
        //   title: t.person.category.uebertragung,
        //   content: <Uebertragungen person={data} data={null} set={null} />,
        //   id: 'uebertragung',
        // },
        // {
        //   title: t.person.category.inaktivitaet,
        //   content: <Inaktivitaet data={data.inaktivitaet} />,
        //   status: statusValidator(ValidationProp.INAKTIVITAET),
        //   id: 'inaktivitaet',
        // },
      )
    }
    return items
  }, [data, t])

  useEffect(() => {
    if (data) {
      const hasMissingNeededInformation = items().reduce((acc, item) => {
        if (typeof item.status === 'boolean') {
          if (!item.status) return true
        } else if (!!item.status) {
          if (item.status.missingNEEDED.length > 0) return true
        }
        return acc
      }, false)
      if (hasMissingInformation !== hasMissingNeededInformation) {
        setHasMissingInformation(hasMissingNeededInformation)
      }
    }
  }, [data, hasMissingInformation, items])

  if (!data) return null
  return (
    <Container>
      <div className="flex flex-col gap-1 pt-10 pb-20">
        <div className="flex gap-3 items-center">
          <UserGroupIcon className="stroke-blue w-10" />
          <div className="font-bold text-lg text-blue">{t.member.informationen.title(data)}</div>
        </div>
        <Accordion className="mt-3" items={items()} initIndex={0} />
      </div>
    </Container>
  )
}

export default Upsert
