import { useCallback, useEffect, useState } from 'react'
import {
  Item,
  Storage,
  StorageType,
  SwitchFolder,
  CreateFolder,
  Folder,
  ItemType,
  RemoveItem,
  CreateFiles,
  File,
} from '../../../../types/Storage/Storage'
import { crud, functional } from '@think-internet/zeus-frontend-package'
import useToast from '../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { storage_root_folder } from '../../../../utility'
import Routes from '../../../../redux/routes'
import { File as GenericFile } from '../../../../types/generic'

const useStorage = (storageType: StorageType, targetUUID: string): Storage => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const storageItemFeature = crud.use(props.STORAGE_ITEM)
  const listItems = functional.use(Routes.STORAGE_LIST_ITEMS)
  const [items, setItems] = useState<Item[]>(null)
  const [currentFolderUUID, setCurrentFolderUUID] = useState<string | null>(null)

  const fetchItems = useCallback(
    async (folderUUID: string) => {
      const resolvedFolderUUID = folderUUID || storage_root_folder
      if (currentFolderUUID !== resolvedFolderUUID) {
        const items = await listItems({ storageType, targetUUID, folderUUID: resolvedFolderUUID })
        setItems(items)
        setCurrentFolderUUID(resolvedFolderUUID)
      }
    },
    [currentFolderUUID, listItems, targetUUID, storageType],
  )

  useEffect(() => {
    if (!items) {
      fetchItems(storage_root_folder)
    }
  }, [items, fetchItems])

  const switchFolder: SwitchFolder = (uuid: string) => {
    fetchItems(uuid)
  }

  const createFolder: CreateFolder = async (name: string) => {
    const folder: Folder = {
      storageType,
      type: ItemType.FOLDER,
      name,
      folderUUID: currentFolderUUID,
      targetUUID,
    }
    const createdFolder = await storageItemFeature.create(folder)
    if (!!createdFolder) {
      setItems([...items, createdFolder])
    } else {
      toast(t.storage.error.createFolder)
    }
  }

  const createFiles: CreateFiles = async (files: GenericFile[]) => {
    const createdFiles: File[] = []

    for (const file of files) {
      const fileObject: File = {
        storageType,
        type: ItemType.FILE,
        name: file.name,
        size: file.size,
        key: file.key,
        mimeType: file.mimeType,
        folderUUID: currentFolderUUID,
        targetUUID,
      }
      const createdFile = await storageItemFeature.create(fileObject)
      if (!!createdFile) {
        createdFiles.push(createdFile)
      }
    }
    setItems([...items, ...createdFiles])
  }

  const removeItem: RemoveItem = (uuid: string) => async () => {
    const removed = await storageItemFeature.remove({ uuid })
    if (removed) {
      setItems(items.filter((item) => item.uuid !== uuid))
    } else {
      toast(t.settings.storageTemplate.error.removeItem)
    }
  }

  return { items, switchFolder, createFolder, createFiles, removeItem, currentFolderUUID }
}

export default useStorage
