import { StorageType } from '../../../../types/Storage/Storage'
import Loading from '../../../UI/Loading/Loading'
import ItemList from '../../../UI/Storage/ItemList/ItemList'
import CreateFolder from '../CreateFolder'
import useStorage from '../hooks/useStorage'
import UploadFile from '../UploadFile/UploadFile'

type Props = { storageType: StorageType; targetUUID: string }

const Explorer: React.FC<Props> = ({ storageType, targetUUID }) => {
  const { items, switchFolder, createFolder, createFiles, removeItem, currentFolderUUID } = useStorage(storageType, targetUUID)

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 justify-end">
        <CreateFolder callback={createFolder} />
        <UploadFile targetUUID={targetUUID} folderUUID={currentFolderUUID} addFiles={createFiles} />
      </div>
      {!items && <Loading loading={items} />}
      {!!items && <ItemList items={items} disableTemplateInteractions switchFolder={switchFolder} removeItem={removeItem} />}
    </div>
  )
}

export default Explorer
