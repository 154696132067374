import { useDispatch, useSelector } from 'react-redux'
import { getUUID } from '../../../../../../utility'
import Default from './Item/Default'
import { useNavigate } from 'react-router-dom'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import props from '../../../../../../redux/props'
import Cookies from 'universal-cookie'
import { setLocal } from '../../../../../../redux/action/local'
import { NavigationItem } from '../../types'
import Button from '../../../../Button/Button'

type Props = {
  mobile?: boolean
  onClick?: () => void
}

const Items: React.FC<Props> = ({ mobile = false, onClick = null }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoggedIn = () => !!accountLink

  const logout = () => {
    if (onClick) {
      onClick()
    }
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    dispatch(setLocal(props.ACCOUNT_LINK, null))
    dispatch(setLocal(props.TOKEN, null))
    navigate('/')
  }

  return (
    <>
      {isLoggedIn() ? (
        <>
          {t.header.navigation[accountLink.level].map((item: NavigationItem) => (
            <Default onClick={onClick} key={getUUID()} data={item} />
          ))}
          <Button inverted={mobile} text={t.generic.logout} onClick={logout} />
        </>
      ) : (
        <>
          {t.header.navigation.default.map((item: NavigationItem) => (
            <Default mobile={mobile} onClick={onClick} key={getUUID()} data={item} />
          ))}
        </>
      )}
    </>
  )
}

export default Items
