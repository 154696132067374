import { Route, Routes } from 'react-router-dom'
import List from './List'
import View from './View/View'

const Company = () => {
  return (
    <Routes>
      <Route path={'/:uuid'} element={<View />} />
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}

export default Company
