import Input from '../../../../UI/Input/Input'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import { Steuer as SteuerType } from '../../../../../types/Person'

const Steuer: React.FC<SectionProps<SteuerType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isReadOnly = !set

  const internalSet = (key: keyof SteuerType) => (value: any) => {
    if (!isReadOnly) {
      const newData = { ...data, [key]: value }
      set(newData)
    }
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper itemsPerRow={3}>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.steuernummer}
          value={data['steuernummer']}
          onChange={internalSet('steuernummer')}
        />
      </InputWrapper>
      <InputWrapper itemsPerRow={3}>
        <Input disabled={isReadOnly} label placeholder={t.attributes.steuerID} value={data['steuerID']} onChange={internalSet('steuerID')} />
      </InputWrapper>
      <InputWrapper itemsPerRow={3}>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.mandantennummer}
          value={data['mandantennummer']}
          onChange={internalSet('mandantennummer')}
        />
      </InputWrapper>
    </div>
  )
}

export default Steuer
