import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'

const Onliner = ({ value, onChange, required = false, disabled = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const update = (value: string) => {
    onChange(value === 'true')
  }

  return <Dropdown disabled={disabled} label={t.UI.onliner.title} required={required} items={t.UI.onliner.items} value={value} onChange={update} />
}

export default Onliner
