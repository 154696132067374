import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Prio, TargetType, Todo } from '../../../../types/Todo/Todo'
import Item from './Item'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import { useState } from 'react'

type Props = {
  todos: Todo[]
  title: string
}

const List: React.FC<Props> = ({ todos, title }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [prio, setPrio] = useState<Prio>(null)
  const [targetType, setTargetType] = useState<TargetType>(null)

  const sortAndFilter = (todos: Todo[]) => {
    const prioMap = {
      [Prio.LOW]: 1,
      [Prio.MEDIUM]: 2,
      [Prio.HIGH]: 3,
    }
    const sorted = todos.sort((a, b) => prioMap[b.prio] - prioMap[a.prio])
    const byPriority = prio ? sorted.filter((todo) => todo.prio === prio) : sorted
    return targetType ? byPriority.filter((todo) => todo.targetType === targetType) : byPriority
  }

  return (
    <div className="py-3">
      <div className="flex justify-between items-center">
        <div className="font-bold">{title}</div>
        <div className="flex gap-3">
          <div>
            <Dropdown
              value={prio}
              onChange={setPrio}
              emptySelection={t.todo.prio.all}
              items={Object.keys(Prio).map((key) => ({ label: t.todo.prio[key], value: key }))}
            />
          </div>
          <div>
            <Dropdown
              value={targetType}
              onChange={setTargetType}
              emptySelection={t.todo.targetType.all}
              items={Object.keys(TargetType).map((key) => ({ label: t.todo.targetType[key], value: key }))}
            />
          </div>
        </div>
      </div>
      <div className="max-h-[30vh] overflow-x-hidden overflow-y-auto pt-3 flex flex-col gap-2">
        {todos.length === 0 && <div className="text-sm">{t.todo.empty}</div>}
        {todos.length > 0 && sortAndFilter(todos).map((todo, index) => <Item key={index} todo={todo} />)}
      </div>
    </div>
  )
}

export default List
