import { Route, Routes } from 'react-router-dom'
import View from './View/View'

const Person = () => {
  return (
    <Routes>
      <Route path={'/'} element={<View />} />
    </Routes>
  )
}

export default Person
