import { functional } from '@think-internet/zeus-frontend-package'
import { useState } from 'react'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import Routes from '../../../../redux/routes'
import { File as GenericFile } from '../../../../types/generic'

type Response = {
  key: string
  presignedURL: string
}

export enum FileUploadStatus {
  READY,
  UPLOADING,
}

export type FileUploadState = {
  total?: number
  remaining?: number
  progress?: number
  workingOn?: string
  status: FileUploadStatus
}

type UseFileUpload = {
  fileUpload: (targetUUID: string, folderUUID: string, files: File[]) => Promise<GenericFile[]>
  state: FileUploadState
}

const useFileUpload = (): UseFileUpload => {
  const [state, setState] = useState<FileUploadState>({ status: FileUploadStatus.READY })
  const getUploadPresignedURL = functional.use(Routes.STORAGE_GET_PRESIGNED_UPLOAD_URL)

  const updateProgress = (progress: number) => {
    setState((state) => ({
      ...state,
      progress,
    }))
  }

  const reduceRemaining = () => {
    setState((state) => ({
      ...state,
      remaining: state.remaining - 1,
    }))
  }

  const workingOn = (name: string) => {
    setState((state) => ({
      ...state,
      workingOn: name,
    }))
  }

  const resetState = () => {
    setState((state) => ({
      ...state,
      progress: 0,
      status: FileUploadStatus.READY,
    }))
  }

  const fileUpload = async (targetUUID: string, folderUUID: string, files: File[]) => {
    setState({
      total: files.length,
      remaining: files.length,
      progress: 0,
      status: FileUploadStatus.UPLOADING,
    })
    const createdFiles: GenericFile[] = []
    for (const file of files) {
      workingOn(file.name)
      const response: Response = await getUploadPresignedURL({
        targetUUID,
        folderUUID,
        name: file.name,
        mimeType: file.type,
      })
      if (!response) continue
      const { key, presignedURL } = response

      const uploadResult = await s3Upload(presignedURL, file, updateProgress)
      if (!uploadResult) continue
      reduceRemaining()
      createdFiles.push({
        key,
        name: file.name,
        mimeType: file.type,
        size: file.size,
      })
    }
    resetState()
    return createdFiles
  }

  return {
    fileUpload,
    state,
  }
}

export default useFileUpload
