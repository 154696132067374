import _ from 'lodash'
import { StatusObject } from '../../../../UI/Accordion/Section/StatusBell'
import { steuer, kontakt } from './propertyLists'
import { Property, Required, ValidationProp } from './types'
import { Person } from '../../../../../types/Person'

type ReturnFunction = (prop: ValidationProp) => StatusObject

const useStatusValidator = (data: Person): ReturnFunction => {
  const checkValue = (value: any): boolean => {
    if (Array.isArray(value)) {
      return value.length === 0
    } else {
      return value === '' || value === undefined || value === null || value === 0
    }
  }

  const validate = (baseProp: string, properties: Property[]): StatusObject => {
    const missingNEEDED = properties.filter(
      (property) => property.required === Required.NEEDED && checkValue(_.get(data, `${baseProp}.${property.name}`)),
    )
    const missingOPTIONAL = properties.filter(
      (property) => property.required === Required.OPTIONAL && checkValue(_.get(data, `${baseProp}.${property.name}`)),
    )
    return {
      missingNEEDED: missingNEEDED.map((property) => property.name),
      missingOPTIONAL: missingOPTIONAL.map((property) => property.name),
    }
  }

  if (!data) {
    return (): StatusObject => ({ missingNEEDED: [], missingOPTIONAL: [] })
  }
  return (prop): StatusObject => {
    switch (prop) {
      case ValidationProp.KONTAKT:
        return validate('kontakt', kontakt)
      case ValidationProp.STEUER:
        return validate('steuer', steuer)
    }
  }
}

export default useStatusValidator
