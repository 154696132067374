import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { Todo } from '../../../../types/Todo/Todo'
import Routes from '../../../../redux/routes'

const useTodoListAdmin = (): Todo[] | false => {
  const [todos, setTodos] = useState<Todo[] | false>(null)
  const listTodos = functional.use(Routes.TODO_LIST_ADMIN)

  useEffect(() => {
    const pull = async () => {
      const beteiligungen = await listTodos({})
      if (Array.isArray(beteiligungen)) {
        setTodos(beteiligungen)
      } else {
        setTodos(false)
      }
    }
    if (todos === null) {
      pull()
    }
  }, [todos, listTodos])

  return todos
}

export default useTodoListAdmin
