import { File as GenericFile } from '../../types/generic'

export enum StorageType {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}

export enum ItemType {
  FILE = 'FILE',
  FOLDER = 'FOLDER',
}

type SharedProps = {
  uuid?: string
  storageType: StorageType
  type: ItemType
  folderUUID: string
  name: string
  // in case of a file or folder specifically for a company or person
  targetUUID?: string
}

export type File = SharedProps & {
  uuid?: string
  type: ItemType.FILE
  size: number
  mimeType: string
  key: string
  // dynamic properties
  url?: string
}

export type Folder = SharedProps & {
  type: ItemType.FOLDER
}

export type Item = File | Folder

export type SwitchFolder = (uuid: string) => void
export type CreateFolder = (name: string) => void
export type CreateFiles = (file: GenericFile[]) => void
export type RemoveItem = (uuid: string) => () => void

export type Storage = {
  uuid?: string
  items: Item[]
  switchFolder: SwitchFolder
  createFolder: CreateFolder
  createFiles?: CreateFiles
  removeItem: RemoveItem
  currentFolderUUID: string
}
