import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'
import { Level as LevelEnum } from '../../../types/Auth'

const Level = ({ value, onChange, required = false, disabled = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Dropdown
      label={t.UI.level.title}
      value={value}
      onChange={onChange}
      disabled={disabled}
      items={[
        { value: LevelEnum.ADMIN, label: t.UI.level.select[LevelEnum.ADMIN] },
        { value: LevelEnum.MEMBER, label: t.UI.level.select[LevelEnum.MEMBER] },
      ]}
    />
  )
}

export default Level
