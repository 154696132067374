import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'
import { Rechtsform } from '../../../types/Company'

const CompanyLegalTypes = ({ value, onChange, required = false, disabled = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Dropdown
      label={t.UI.companyLegalTypes.title}
      required={required}
      items={Object.keys(Rechtsform).map((c) => ({ label: t.UI.companyLegalTypes.types[c], value: c }))}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default CompanyLegalTypes
