import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../../Upsert'
import Add from './Add'
import { Kontaktpersonen as KontaktpersonenType } from '../../../../../../types/Company'

const Kontaktpersonen: React.FC<SectionProps<KontaktpersonenType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isReadOnly = !set

  return (
    <div className="flex flex-col gap-[10px]">
      {!isReadOnly && (
        <div className="flex items-center justify-between">
          <div>{t.company.kontaktpersonen.hint}</div>
          <Add data={data} set={set} />
        </div>
      )}
      <div className="flex flex-col gap-1">
        {(Array.isArray(data.internal) && data.internal.length > 0) || (Array.isArray(data.external) && data.external.length > 0) ? (
          <>
            {data.external?.map((person, index) => (
              <div className="flex flex-row gap-10 w-full" key={index}>
                <div className="flex-1 shrink-0 truncate">{person.vorname}</div>
                <div className="flex-1 shrink-0 truncate">{person.nachname}</div>
                <div className="flex-1 shrink-0 truncate">{person.mail}</div>
                <div className="flex-1 shrink-0 truncate">{person.telefon}</div>
              </div>
            ))}
            {data.internalResolved?.map((person, index) => (
              <div className="flex flex-row gap-10 w-full" key={index}>
                <div className="flex-1 shrink-0 truncate">{person.kontakt.vorname}</div>
                <div className="flex-1 shrink-0 truncate">{person.kontakt.nachname}</div>
                <div className="flex-1 shrink-0 truncate">{person.kontakt.mail}</div>
                <div className="flex-1 shrink-0 truncate">{person.kontakt.telefon}</div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-2 text-center text-sm">{t.company.kontaktpersonen.empty}</div>
        )}
      </div>
    </div>
  )
}

export default Kontaktpersonen
