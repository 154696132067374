import { ListBulletIcon } from '@heroicons/react/24/solid'
import Container from '../../UI/Container/Container'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import useTodoListAdmin from './hooks/useTodoListAdmin'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useEffect, useState } from 'react'
import { Todo as TodoType } from '../../../types/Todo/Todo'
import Loading from '../../UI/Loading/Loading'
import List from './List/List'

type Data = {
  primary: TodoType[]
  secondary: TodoType[]
}

const Todo = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const todos = useTodoListAdmin()
  const accountLink: AccountLink = useSelector((state) => state[props.ACCOUNT_LINK])
  const [data, setData] = useState<Data>(null)

  useEffect(() => {
    if (accountLink && Array.isArray(todos) && !data) {
      const primary = todos.filter((todo) => todo.primaryReceiverPersonUUIDList.includes(accountLink.assignedToUUID as string))
      const secondary = todos.filter((todo) => todo.secondaryReceiverPersonUUIDList.includes(accountLink.assignedToUUID as string))
      setData({ primary, secondary })
    }
  }, [todos, accountLink, data])

  return (
    <Container>
      <div className="py-10">
        <div className="flex gap-3 items-center">
          <div className="flex gap-3 items-center">
            <ListBulletIcon className="fill-blue w-10" />
            <div className="font-bold text-lg text-blue">{t.todo.title}</div>
          </div>
        </div>
        <Loading loading={data} />
        {!!data && (
          <div className="flex flex-col gap-10 mt-5 divide-y divide-lightGray">
            <List todos={data?.primary} title={t.todo.primary} />
            <List todos={data?.secondary} title={t.todo.secondary} />
          </div>
        )}
      </div>
    </Container>
  )
}

export default Todo
