import { crud } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FolderIcon } from '@heroicons/react/24/outline'
import props from '../../../../redux/props'
import { Company } from '../../../../types/Company'
import { useParams } from 'react-router-dom'
import MediumContainer from '../../../UI/Container/MediumContainer'
import SideNavigation from '../../../UI/Layout/SideNavigation/SideNavigation'
import Explorer from '../../../UI/Storage/Explorer/Explorer'
import { StorageType } from '../../../../types/Storage/Storage'

const Storage = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const companyFeature = crud.use(props.COMPANY)
  const [company, setCompany] = useState<Company>(null)
  const { companyUUID } = useParams()

  useEffect(() => {
    const pull = async () => {
      const result = await companyFeature.get({ uuid: companyUUID })
      if (result) {
        setCompany(result)
      }
    }
    if (!company) {
      pull()
    }
  }, [company, companyFeature, companyUUID])

  if (!company) return <></>
  return (
    <div className="mt-10 pb-20">
      <MediumContainer>
        <div className="flex flex-row items-center gap-3 justify-between">
          <a href={`/admin/company/${company.uuid}`} className="overflow-hidden flex gap-3 items-center justify-start">
            <FolderIcon className="storke-blue w-8 shrink-0" />
            <div className="font-bold text-lg text-blue truncate">{`${company.base.name} - ${t.storage.title}`}</div>
          </a>
        </div>
      </MediumContainer>
      <MediumContainer>
        <div className="relative mt-3">
          <SideNavigation uuid={company.uuid} items={t.company.navigation} />
          <Explorer storageType={StorageType.COMPANY} targetUUID={company.uuid} />
        </div>
      </MediumContainer>
    </div>
  )
}

export default Storage
