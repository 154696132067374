import { functional } from '@think-internet/zeus-frontend-package'
import { TargetType, Type } from '../../../../types/Todo/Todo'
import Routes from '../../../../redux/routes'

type Remove = (type: Type, targetType: TargetType, payloadUUID: string) => Promise<boolean>

const useTodoRemoveAdmin = (): Remove => {
  const removeTodo = functional.use(Routes.TODO_REMOVE_ADMIN)

  const remove: Remove = async (type, targetType, payloadUUID) => {
    const status = await removeTodo({ type, targetType, payloadUUID })
    return status
  }

  return remove
}

export default useTodoRemoveAdmin
