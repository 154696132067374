import { useSelector } from 'react-redux'
import { Item as ItemType, RemoveItem, SwitchFolder } from '../../../../types/Storage/Storage'
import props from '../../../../redux/props'
import Item from './Item'

type Props = {
  items: ItemType[]
  switchFolder: SwitchFolder
  removeItem?: RemoveItem
  disableTemplateInteractions?: boolean
}

const ItemList: React.FC<Props> = ({ items, switchFolder, removeItem, disableTemplateInteractions }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!Array.isArray(items)) return null
  return (
    <div>
      {items.length === 0 && <div className="text-sm text-center">{t.storage.itemList.empty}</div>}
      {items.length > 0 && (
        <div>
          {items.map((item) => (
            <Item
              disableTemplateInteractions={disableTemplateInteractions}
              item={item}
              switchFolder={switchFolder}
              removeItem={removeItem}
              key={item.uuid}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ItemList
