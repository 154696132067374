import { useSelector } from 'react-redux'
import { TargetType } from '../../../../../types/Todo/Todo'
import props from '../../../../../redux/props'
import { BuildingOffice2Icon, UserIcon } from '@heroicons/react/24/outline'

type Base = {
  title: string
  Icon: any
}

type Content = Base & {
  href: string
}

const useGetContent = (todo): Content => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (todo.targetType === TargetType.COMPANY) {
    const base: Base = {
      title: t.todo.content.title[todo.type](todo.company?.base.name),
      Icon: BuildingOffice2Icon,
    }
    return {
      ...base,
      href: `/admin/company/${todo.company?.uuid}`,
    }
  } else if (todo.targetType === TargetType.PERSON) {
    const base: Base = {
      title: t.todo.content.title[todo.type](`${todo.person?.kontakt.vorname} ${todo.person?.kontakt.nachname}`),
      Icon: UserIcon,
    }
    return {
      ...base,
      href: `/admin/person/${todo.person?.uuid}`,
    }
  } else {
    return null
  }
}

export default useGetContent
