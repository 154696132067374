import { functional } from '@think-internet/zeus-frontend-package'
import { Payload, Prio, TargetType, Type } from '../../../../types/Todo/Todo'
import Routes from '../../../../redux/routes'

type Create = (type: Type, targetType: TargetType, prio: Prio, payload: Payload) => Promise<boolean>

const useTodoCreateAdmin = (): Create => {
  const createTodo = functional.use(Routes.TODO_CREATE_ADMIN)

  const create: Create = async (type, targetType, prio, payload) => {
    const status = await createTodo({ type, targetType, prio, payload })
    return status
  }

  return create
}

export default useTodoCreateAdmin
