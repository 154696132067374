import { useCallback, useEffect, useState } from 'react'
import { Item, Storage, StorageType, SwitchFolder, CreateFolder, Folder, ItemType, RemoveItem } from '../../../../../types/Storage/Storage'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../../redux/props'
import useToast from '../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import { storage_root_folder } from '../../../../../utility'

const useStorageTemplate = (storageType: StorageType): Storage => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const storageTemplateFolderFeature = crud.use(props.STORAGE_TEMPLATE_FOLDER)
  const [items, setItems] = useState<Item[]>(null)
  const [currentFolderUUID, setCurrentFolderUUID] = useState<string | null>(null)

  const fetchItems = useCallback(
    async (folderUUID: string) => {
      const resolvedFolderUUID = folderUUID || storage_root_folder
      if (currentFolderUUID !== resolvedFolderUUID) {
        const items: Item[] = await storageTemplateFolderFeature.list({ storageType, folderUUID: resolvedFolderUUID })
        setItems(items.filter((item) => !item.targetUUID))
        setCurrentFolderUUID(resolvedFolderUUID)
      }
    },
    [currentFolderUUID, storageTemplateFolderFeature, storageType],
  )

  useEffect(() => {
    if (!items) {
      fetchItems(storage_root_folder)
    }
  }, [items, fetchItems])

  const switchFolder: SwitchFolder = (uuid: string) => {
    fetchItems(uuid)
  }

  const createFolder: CreateFolder = async (name: string) => {
    const folder: Folder = {
      storageType,
      type: ItemType.FOLDER,
      name,
      folderUUID: currentFolderUUID,
    }
    const createdFolder = await storageTemplateFolderFeature.create(folder)
    if (!!createdFolder) {
      setItems([...items, createdFolder])
    } else {
      toast(t.storage.error.createFolder)
    }
  }

  const removeItem: RemoveItem = (uuid: string) => async () => {
    const removed = await storageTemplateFolderFeature.remove({ uuid })
    if (removed) {
      setItems(items.filter((item) => item.uuid !== uuid))
    } else {
      toast(t.storage.error.removeItem)
    }
  }

  return { items, switchFolder, createFolder, removeItem, currentFolderUUID }
}

export default useStorageTemplate
