import { StorageType } from '../../../../types/Storage/Storage'
import Loading from '../../../UI/Loading/Loading'
import CreateFolder from '../../../UI/Storage/CreateFolder'
import ItemList from '../../../UI/Storage/ItemList/ItemList'
import useStorageTemplate from './hooks/useStorageTemplate'

const StorageTemplate: React.FC<{}> = () => {
  const { items, switchFolder, createFolder, removeItem } = useStorageTemplate(StorageType.COMPANY)
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 justify-end">
        <CreateFolder callback={createFolder} />
      </div>
      {!items && <Loading loading={items} />}
      {!!items && <ItemList disableTemplateInteractions={false} items={items} switchFolder={switchFolder} removeItem={removeItem} />}
    </div>
  )
}

export default StorageTemplate
