import { Prio, Todo } from '../../../../types/Todo/Todo'
import useGetContent from './helper/useGetContent'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

type Props = {
  todo: Todo
}

const Bell = ({ prio }: { prio: Prio }) => {
  const getColor = () => {
    if (prio === Prio.LOW) return 'bg-green'
    if (prio === Prio.MEDIUM) return 'bg-orange'
    return 'bg-red'
  }

  return <div className={`w-4 aspect-square rounded-full ${getColor()}`}></div>
}

const Item: React.FC<Props> = ({ todo }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const content = useGetContent(todo)

  if (!content) return null
  return (
    <div>
      <div className="flex gap-3 items-center justify-between">
        <div className="flex gap-2 items-center">
          <Bell prio={todo.prio} />
          <div className="font-bold text-lg">
            <content.Icon className="stroke-blue w-5" />
          </div>
          <div>{content.title}</div>
        </div>
        <a href={content.href} className="hover:underline font-bold">
          {t.todo.open}
        </a>
      </div>
    </div>
  )
}

export default Item
